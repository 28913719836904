





















































import { Component, Vue } from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'

import LandingForm from './LandingForm.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'LandingHeader',
  components: { LandingForm, BaseButton, BaseIcon },
})
export default class LandingHeader extends Vue {
  breakpoints = breakpoints
}
